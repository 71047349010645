<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <v-alert
        v-if="loginError"
        color="danger"
        dismissible
      >
        {{ loginError.message }}
      </v-alert>
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <h6 class="text--disabled font-weight-medium mb-10">
            Login to your account
          </h6>
          <v-form>
            <v-text-field
              v-model="email"
              label="email"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="ePassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="password"
              :rules="nameRules"
              @click:append="show = !show"
            />
            <v-checkbox
              v-model="checkbox1"
              label="Remember this computer"
            />
            <v-btn
              class="mb-4"
              block
              color="primary"
              dark
              @click="formSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              Sign In
            </v-btn>
            <div class="d-flex justify-around flex-wrap">
              <v-btn
                text
                small
                color="primary"
                class="mb-2"
              >
                Forgot Password
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                to="/register"
              >
                Create an Account
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Login',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Login',
    },
    data () {
      return {
        show: false,
        password: '',
        checkbox1: true,
        checkbox2: false,
        email: '',
        ePassword: '',
        loading: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          (v) => !!v || 'Password is required',
        ],
      }
    },
    created () {
      this.apiStatus('/login')
    },
    computed: {
      ...mapGetters(['loggedInUser', 'loginError']),
    },
    methods: {
      ...mapActions(['login', 'apiStatus']),
      formSubmit () {
        this.login({ email: this.email, password: this.ePassword })
      },
    },
    watch: {
      loggedInUser (val) {
        if (val && val.uid && val.uid.length > 0) {
          // this.makeToast("success", "Successfully Logged In");
          console.log('logged in successfully ')
          this.loading = true
          setTimeout(() => {
            this.$router.push('/')
          }, 500)
        }
      },
      // loginError(val) {
      //     if (val != null) {
      //         this.makeToast("warning", val.message);
      //     }
      // }
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
